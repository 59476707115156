<template>
<div>
  <v-alert v-if="router.currentRoute.query.showBookingMessage == 'success'" type="success" >
    Sie haben die Kampagne erfolgreich gebucht. Sobald Ihre Zahlung bestätigt wurde, erhalten Sie eine E-Mail von uns.
  </v-alert>
  <v-alert v-else-if="router.currentRoute.query.showBookingMessage == 'error'" type="error" >
    Die Buchung ist fehlgeschlagen. Bitte wenden Sie sich an den Support.
  </v-alert>
  <v-row>
    <v-col
      cols="12"
      sm="4"
    >
      <statistics-card-with-images
        class="fill-height"
        :avatar="ratingsOptions.avatar"
        :icon="ratingsOptions.icon"
        :avatar-width="ratingsOptions.avatarWidth"
        :chip-color="ratingsOptions.chipColor"
        :chip-text="ratingsOptions.chipText"
        :statistics="totalAmountList"
        :stat-title="ratingsOptions.statTitle"
      ></statistics-card-with-images>
    </v-col>
    <v-col
      cols="12"
      sm="8"
    >
      <v-card class="fill-height">
        <v-card-title>Suchen</v-card-title>
        <v-card-text>
          <div class="d-flex align-center pb-5">
            <v-text-field
              v-model="filter.searchString"
              dense
              outlined
              hide-details
              clearable
              placeholder="Kampagne suchen"
              class="channel-list-search me-3"
            ></v-text-field>
            <v-select
              v-model="filter.status"
              label="Status"
              outlined
              class="channel-list-search me-3"
              dense
              hide-details
              :items="campaignStates"
              item-text="text"
              item-value="value"
            ></v-select>
            <inline-date-picker v-model="filter.datumVon" label="Erstellt von"/>
            <inline-date-picker v-model="filter.datumBis" label="Erstellt bis"/>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col
      cols="12"
      sm="12"
    >
      <v-card>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <template v-if="showCreateButton" >
            <div class="d-flex align-center pb-5">
              <template >
                <router-link :to="'/kampagne/erstellen'" >
                  <v-btn
                    color="accent"
                    class="me-3"
                  >
                    <v-icon size="30" class="pr-2">{{icons.mdiAccountGroup}}</v-icon>
                    <span>Kampagne erstellen</span>
                  </v-btn>
                </router-link>
              </template>
            </div>

            <v-spacer></v-spacer>
            <div class="d-flex align-center pb-5">
              <template >
                <router-link :to="'/kampagne/statistiken'" >
                  <v-btn
                    color="default"
                    class="me-3"
                  >
                    <v-icon size="30" class="pr-2">{{icons.mdiChartArc}}</v-icon>
                    <span>Kampagnenstatistiken</span>
                  </v-btn>
                </router-link>
              </template>
            </div>
          </template>
          <template v-else>
            <v-alert
              dense
              text
              color="error"
              border="left"
            >
              Um eine Kampagne erstellen zu können, müssen Sie zunächst <router-link to="/konto?showMissingFields=1"><a href="">Ihre Daten</a></router-link> vervollständigen.
            </v-alert>
          </template>
        </v-card-text>
      </v-card>
      <v-data-table
        v-model="selectedTableData"
        :headers="headers"
        :items="listCampaigns"
        :options.sync="options"
        :server-items-length="totalAmountList"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        item-key="idPtKampagne"
        :search="filter.searchString"
        class="text-no-wrap"
        no-data-text="Keine Einträge vorhanden"
        no-results-text="Ihre Suche ergab keinen Treffer"
        :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
      >
        <template
          v-slot:top
        >
          <v-dialog
            v-model="dialogDelete"
            width="500px"
          >
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>Möchten Sie {{ deleteItem.name }} wirklich löschen?</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDelete">
                  <v-icon size="18" >
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="deleteCampaignConfirm"
                >
                  Löschen
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="closeDelete"
                >
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogCampaignInfo"
            width="1200px"
          >
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>{{editItem.displayName}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialogCampaignInfo">
                  <v-icon size="18" >
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="pr-10 pl-10">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td class="text-left text-uppercase">Aktion Name</td>
                      <td class="text-right pa-3">{{editItem.name}}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-uppercase">Datenmenge</td>
                      <td class="text-right pa-3">{{editItem.anzahlEmpfaenger}}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-uppercase">Versanddatum</td>
                      <td class="text-right pa-3">{{editItem.zeitVersandStartDE}}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-uppercase">Werbemittel</td>
                      <td class="text-right pa-3">{{editItem.adMaterial.displayName}}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-uppercase">Zielgruppe</td>
                      <td class="text-right pa-3">{{editItem.targetGroup.displayName}}</td>
                    </tr>
                    <tr>
                      <td class="text-left text-uppercase">Datenquelle</td>
                      <td class="text-right pa-3">
                        <template v-if="editItem.targetGroup.datenquelle == 'eigenerUpload'">
                          <a
                            :href="`/api/zielgruppe/?aktion=downloadTargetGroupAsCsv&mainData[idPtZielgruppe]=${editItem.targetGroup.idPtZielgruppe}`"
                            :download="`${editItem.targetGroup.eigenerUploadIndex}.csv`"
                          >
                            {{editItem.targetGroup.datenquelleDisplayName}}.csv
                          </a>
                        </template>
                        <template v-else>
                          {{editItem.targetGroup.datenquelleDisplayName}}
                        </template>
                      </td>
                    </tr>
                    <template v-if="editItem.targetGroup.datenquelle == 'bestandsdaten'">
                      <tr v-if="editItem.targetGroup.suchtyp == 'radius'">
                        <td class="text-left text-uppercase">Umkreis</td>
                        <td class="text-right pa-3">{{editItem.targetGroup.adresse}} - {{editItem.targetGroup.umkreisInKm}}km</td>
                      </tr>
                      <tr v-else-if="editItem.targetGroup.suchtyp == 'plzBereich'">
                        <td class="text-left text-uppercase">PLZ-Bereich</td>
                        <td class="text-right pa-3">{{editItem.targetGroup.plzVon}} - {{editItem.targetGroup.plzBis}}</td>
                      </tr>
                      <tr v-else-if="editItem.targetGroup.suchtyp == 'plzListe'">
                        <td class="text-left text-uppercase">PLZ-Liste</td>
                        <td class="text-right pa-3">{{editItem.targetGroup.plzListe}}</td>
                      </tr>
                      <tr>
                        <td class="text-left text-uppercase">Geschlechter</td>
                        <td class="text-right  pa-3">
                          <template v-for="(geschlecht,geschlechtIdx) in editItem.targetGroup.geschlechter">
                            <template v-if="geschlechtIdx > 0"> <br> </template>
                            {{getMatchedItem(genders,"value",geschlecht).label}}
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left text-uppercase">Kaufkraft</td>
                        <td class="text-right pa-3">
                          <template v-for="(kaufkraft,kaufkraftIdx) in editItem.targetGroup.kaufkraftStufen">
                            <template v-if="kaufkraftIdx > 0"> <br> </template>
                            {{getMatchedItem(purchasingPowers,"value",kaufkraft).label}}
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left text-uppercase">Haustypen</td>
                        <td class="text-right pa-3">
                          <template v-for="(haustyp,haustypIdx) in editItem.targetGroup.haustypen">
                            <template v-if="haustypIdx > 0"> <br> </template>
                            {{getMatchedItem(houseTypes,"value",haustyp).label}}
                          </template>
                        </td>
                      </tr>
                    </template>
                    <tr v-if="editItem.status == 'inInternerFreigabe'">
                      <td class="text-left text-uppercase">Freigabelink</td>
                      <td class="text-right pa-3">
                        <v-btn icon @click="copyText">
                          <v-icon size="18" >
                            {{ icons.mdiContentCopy }}
                          </v-icon>
                        </v-btn>
                        {{editItem.freigabeLink}}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogCampaignRelease"
            width="900px"
          >
            <v-card >
              <v-toolbar flat>
                <v-btn v-if="!campaignReleaseConfig.showStep1" icon @click="stepBackCampaignRelease">
                  <v-icon size="18" >
                    {{ icons.mdiArrowLeftCircleOutline }}
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-else></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeCampaignRelease">
                  <v-icon size="18" >
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="pr-10 pl-10 pb-10 d-flex flex-column">
                <template v-if="campaignReleaseConfig.showStep1">
                  <a  class="mb-2" :href="'api/kampagne/bearbeitung_buchung/'+editItem.idPtKampagne" >
                    <v-btn class="primary" block >
                      Sofort freigeben und bezahlen
                    </v-btn>
                  </a>
                  <v-btn class="mb-2 primary" @click="showLinkAndRequestCampaignsRelease">
                    Freigabe anfordern und Link anzeigen
                  </v-btn>
                  <v-btn class="primary" @click="showMailerCampaignsReleaseRequest">
                    Freigabe per E-Mail anfordern
                  </v-btn>
                </template>
                <template v-if="campaignReleaseConfig.showLink">
                  <v-row align="center">
                    <v-col cols="12">
                      <v-alert
                        dense
                        text
                        color="primary"
                        border="left"
                      >
                        Die Kampagne befindet sich ab sofort im Status <span class="warning--text">'Freigabe ausstehend'</span>. <br><span class="error--text">Bitte kopieren Sie den folgenden Link und leiten Sie ihn weiter, um die Freigabe zu ermöglichen.</span>
                      </v-alert>
                    </v-col>
                    <v-col cols="11">
                      <v-text-field
                        v-model="editItem.freigabeLink"
                        label="Kopieren Sie den Link:"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-btn icon @click="copyText">
                        <v-icon size="18" >
                          {{ icons.mdiContentCopy }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="campaignReleaseConfig.showMailer">
                  <div class="text-center" v-if="campaignReleaseConfig.mailerLoading">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-form v-else ref="mailCampaignsReleaseRequest" @submit.prevent="mailCampaignsReleaseRequest">
                    <v-row align="center">
                      <v-col cols="12">
                        <v-alert
                          dense
                          text
                          color="primary"
                          border="left"
                        >
                          Bitte senden Sie den folgenden Link per E-Mail weiter, um die Freigabe zu ermöglichen.
                        </v-alert>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          :rules="[validators.required,validators.emailValidator]"
                          type="email"
                          v-model="editItem.freigabeLinkEmail"
                          label="Per E-Mail weiterleiten an:"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-btn
                          color="primary"
                          type="submit"
                        >
                          E-Mail senden
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </template>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`item.idPtKampagne`]="{item}">
          #{{ item.idPtKampagne }}
        </template>

        <template #[`item.name`]="{item}">


          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : item.avatarColor"
              :class="`v-avatar-light-bg info--text`"
              size="30"
            >
              <v-icon size="18" v-if="item.adMaterial.format == 'maxipostkarte'" class="info--text">
                {{ icons.mdiCardBulletedOutline  }}
              </v-icon>
              <v-icon size="18" v-if="item.adMaterial.format == 'dina4mailing'" class="info--text">
                {{ icons.mdiCardBulletedOutline  }}
              </v-icon>
            </v-avatar>
            <div class="d-flex flex-column ms-3">
              <span class="whitespace-wrap font-weight-semibold">{{ item.name }}</span>
              <small>ID: {{ item.idPtKampagne }}, {{ item.zeitErstellungDE }}</small>
            </div>
          </div>
        </template>

        <template #[`item.angabeBearbeitungAbgeschlossen`]="{item}">
          <v-chip
            small
            :color="item.angabeBearbeitungAbgeschlossen == 1 ? 'success':'secondary'"
            :class="`v-chip-light-bg ${item.angabeBearbeitungAbgeschlossen == 1 ? 'success':'secondary'}--text font-weight-semibold`"
          >
            {{ item.angabeBearbeitungAbgeschlossen == 1 ? 'Ja':'Nein' }}
          </v-chip>
        </template>
        <template #[`item.angabeExterneFreigabeAbgeschlossen`]="{item}">
          <v-chip
            small
            :color="item.angabeExterneFreigabeAbgeschlossen == 1 ? 'success':'secondary'"
            :class="`v-chip-light-bg ${item.angabeExterneFreigabeAbgeschlossen == 1 ? 'success':'secondary'}--text font-weight-semibold`"
          >
            {{ item.angabeExterneFreigabeAbgeschlossen == 1 ? 'Ja':'Nein' }}
          </v-chip>
        </template>
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="getCampaignStateColor(item.status)"
            :class="`v-chip-light-bg ${getCampaignStateColor(item.status)}--text font-weight-semibold`"
            :title="getCampaignStateDescription(item.status)"
          >
            {{ getCampaignStateText(item.status) }}
          </v-chip>
        </template>
        <template #[`item.zahlungsstatus`]="{item}">
          <v-chip
            v-if="item.zahlungsstatus"
            small
            :color="getPaymentColor(item.zahlungsstatus)"
            :class="`v-chip-light-bg ${getPaymentColor(item.zahlungsstatus)}--text font-weight-semibold`"
          >
            {{item.zahlungsstatus}}
          </v-chip>
        </template>

        <template #[`item.naeachsteAktion`]="{item}">
          <v-btn
            small
            class="info"
            @click="campaignReleaseShowStep1( item )"
            v-if="item.status == 'inBearbeitung'"
          >
            Freigeben
          </v-btn>
          <a
            v-if="item.status == 'inBearbeitungKorrektur'"
            :href="'/kampagne/editieren/'+item.idPtKampagne"
          >
            <v-btn
              small
              class="info"
            >
              Korrigieren
            </v-btn>
          </a>
          <a
            v-if="item.status == 'inBezahlung'"
            :href="'api/kampagne/buchung/'+item.idPtKampagne"
          >
            <v-btn
              small
              class="info"
            >
              Bezahlen
            </v-btn>
          </a>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="">

            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <a :href="`/api/kampagne/?aktion=showCampaignAdMaterialPDF&mainData[idPtKampagne]=${item.idPtKampagne}`" target="_blank">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiPencilRuler }}
                    </v-icon>
                  </v-btn>
                </a>
              </template>
              <span>Werbemittel Vorschau</span>
            </v-tooltip>


            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="showCampaignInfo( item )"
                >
                  <v-icon size="18">
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Information</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.status == 'inInternerFreigabe'"
            >
              <template #activator="{ on, attrs }">

                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelCampaignReleaseRequest( item.idPtKampagne )"
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Freigabeanforderung stornieren</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.status == 'inBearbeitung' && item.status != 'inBearbeitungKorrektur'"
            >
              <template #activator="{ on, attrs }">
                <router-link :to="'/kampagne/editieren/'+item.idPtKampagne" >
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </router-link>
              </template>
              <span v-if="item.status == 'inBearbeitungKorrektur'">Korrigieren</span>
              <span v-else>Bearbeiten</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.status == 'inBearbeitung'"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteCampaign( item )"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Löschen</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.status == 'inVersendet'"
            >
              <template #activator="{ on, attrs }">
                <a :href="`/api/dashboard/?aktion=downloadCampaignStatisticsAsPDF&filter[idPtKampagne]=${item.idPtKampagne}`" target="_blank">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiChartBar }}
                    </v-icon>
                  </v-btn>
                </a>
              </template>
              <span>Report PDF</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.idPtRechnung > 0"
            >
              <template #activator="{ on, attrs }">
                <a :href="`/api/rechnung/?aktion=openInvoicePdf&mainData[idPtRechnung]=${item.idPtRechnung}`" >
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiFilePdf }}
                    </v-icon>
                  </v-btn>
                </a>
              </template>
              <span>Rechnung öffnen (PDF)</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCartOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiFilePdf,
  mdiChartBar,
  mdiContentCopy,
  mdiExportVariant,
  mdiAccountGroup,
  mdiPlus,
  mdiCheckboxMultipleMarkedCircle,
  mdiCancel,
  mdiInformationOutline,
  mdiDownload,
  mdiClose,
  mdiArrowLeftCircleOutline,
  mdiChartArc, mdiPencilRuler, mdiCardBulletedOutline
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";
import useHelper from "@/views/useHelper";
import themeConfig from '@themeConfig'
import {emailValidator, required} from "@core/utils/validation";

export default {
  name: 'campaign-list',
  components: {
    InlineDatePicker,
    StatisticsCardWithImages
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const {
      downloadCSVData,
      genders,
      purchasingPowers,
      houseTypes,
      getMatchedItem,
      getCampaignStateColor,
      getCampaignStateDescription,
      getCampaignStateText,
      campaignStates,
      getPaymentColor
    } = useHelper()

    const ratingsOptions = {
      statTitle: 'Mailingkampagnen',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/nitramit/2024-02-26-icon-kampagnen-blau.png'),
      avatarWidth: '111',
    }

    const selectedMultiAction = ref('')
    const selectedTableData = ref([])
    const exportCampaigns = (campaigns) => {
      axios.post('api/kampagne/', {
        aktion: 'getCampaignExportCsvString',
        campaigns: campaigns,
      })
        .then((response) => {
          if(response.data.valid){
            downloadCSVData(response.data.csvString)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const callMultiAction = (event, key) => {
      if(selectedMultiAction.value === "Export"){
        exportCampaigns(selectedTableData.value.map(item => item['idPtKampagne']));
      }
    }

    return {
      vm,
      router,
      ratingsOptions,
      selectedMultiAction,
      selectedTableData,
      callMultiAction,
      exportCampaigns,
      genders,
      purchasingPowers,
      houseTypes,
      getMatchedItem,
      getCampaignStateColor,
      getCampaignStateDescription,
      getPaymentColor,
      getCampaignStateText,
      campaignStates,
      validators: { required, emailValidator },
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      showCreateButton: false,
      icons: {
        mdiChartArc,
        mdiCheckboxMultipleMarkedCircle,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCartOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiChartBar,
        mdiPencilRuler,
        mdiExportVariant,
        mdiAccountGroup   ,
        mdiPlus,
        mdiContentCopy,
        mdiCancel,
        mdiInformationOutline,
        mdiDownload,
        mdiClose,
        mdiArrowLeftCircleOutline,
        mdiCardBulletedOutline,
      },
      filter: {
        searchString: '',
        status: 'all',
        datumVon:'',
        datumBis:''
      },
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idPtKampagne'],
        sortDesc: [true],
      },
      headers: [
        /*{text: '#', align: 'start', value: 'idPtKampagne'},
        {text: 'Erstellt', value: 'zeitErstellungDE'},*/
        {text: 'Kampagne', value: 'name'},
        {text: 'Empfänger', value: 'anzahlEmpfaenger'},
        {text: 'Versanddatum', value: 'zeitVersandStartDE'},
        {text: 'Status', value: 'status'},
        {text: 'Zahlungsstatus', value: 'zahlungsstatus'},
        {text: 'Nächste Aktion', value: 'naeachsteAktion',sortable: false,},
        {
          text: 'Aktionen',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      listCampaigns: [],
      dialogCampaignInfo: false,
      dialogDelete: false,
      dialogCampaignRelease: false,
      deleteItem: {
        idPtKampagne: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idPtKampagne: 0,
        name: '',
        memo: '',
        freigabeLink: '',
        freigabeLinkEmail: '',
        status: 'inBearbeitung',
        targetGroup: {
          displayName : '',
          datenquelle : '',
          suchtyp : '',
          adresse : '',
          umkreisInKm : '',
          plzVon : '',
          plzBis : '',
          plzListe : '',
        },
      },
      defaultItem: {
        idPtKampagne: 0,
        name: '',
        memo: '',
        freigabeLink: '',
        freigabeLinkEmail: '',
        status: 'inBearbeitung',
        targetGroup: {
          displayName : '',
          datenquelle : '',
          suchtyp : '',
          adresse : '',
          umkreisInKm : '',
          plzVon : '',
          plzBis : '',
          plzListe : '',
        },
      },
      campaignReleaseDefaultConfig:{
        showStep1 : false,
        showLink : false,
        showMailer : false,
        mailerLoading : false
      },
      campaignReleaseConfig:{
        showStep1 : false,
        showLink : false,
        showMailer : false,
        mailerLoading : false
      },
      editValidationError : true,
    }
  },
  mounted() {
    if(this.$route.query['filter[status]']) {
      this.filter.status = this.$route.query['filter[status]'];
    }
    if(this.$route.query['filter[searchString]']) {
      this.filter.searchString = this.$route.query['filter[searchString]'];
    }
    this.fetchCampaigns()
  },
  watch: {
    options: {
      handler() {
        this.fetchCampaigns()
      },
    },
    filter: {
      handler() {
        this.fetchCampaigns()
      },
      deep: true,
    },
    dialogCampaignInfo (val) {
      val || this.closeDialogCampaignInfo()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogCampaignRelease (val) {
      val || this.closeCampaignRelease()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Kampagne hinzufügen' : 'Kampagne bearbeiten'
    },
  },
  methods: {
    fetchCampaigns() {
      this.loading = true
      axios.post('api/kampagne/', {
        aktion: 'showOverviewCampaigns',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listCampaigns = response.data.campaigns || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false

            this.showCreateButton = response.data.user.stammdatenVollstaendig;

          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setCampaignState(idPtKampagne, state) {
      axios.post('api/kampagne/', {
        aktion: 'changeCampaignState',
        idPtKampagne: idPtKampagne,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchCampaigns()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteCampaign (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteCampaignConfirm () {
      axios.post('api/kampagne/', {
        aktion: 'deleteCampaign',
        mainData :{idPtKampagne: this.deleteItem.idPtKampagne},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchCampaigns()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeDialogCampaignInfo () {
      this.dialogCampaignInfo = false
    },

    campaignReleaseShowStep1 (item) {
      this.editValidationError = false
      this.dialogCampaignRelease = true
      if(item) {
        this.editIndex = this.listCampaigns.indexOf(item)
        this.editItem = Object.assign({}, item)
        this.campaignReleaseConfig.showStep1 = true;
      }
    },
    showCampaignInfo (item) {
      this.dialogCampaignInfo = true
      this.editValidationError = false
      if(item) {
        this.editIndex = this.listCampaigns.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    showLinkAndRequestCampaignsRelease () {
      this.campaignReleaseConfig.showStep1 = false
      axios.get('/api/kampagne/?aktion=requestCampaignsRelease&mainData[idPtKampagne]='+this.editItem.idPtKampagne)
        .then(
          (response) =>{
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
              this.campaignReleaseConfig.showLink = true
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            this.fetchCampaigns();
          }
        )
        .catch((error) => {
          console.log(error)
        })
    },
    showMailerCampaignsReleaseRequest () {
      this.campaignReleaseConfig.showStep1 = false
      this.campaignReleaseConfig.showMailer = true
    },
    mailCampaignsReleaseRequest () {
      const isFormValid = this.$refs.mailCampaignsReleaseRequest.validate()
      if (!isFormValid) return

      this.campaignReleaseConfig.showStep1 = false
      this.campaignReleaseConfig.mailerLoading = true

      axios.post('api/kampagne/', {
        aktion: 'mailCampaignsCustomersReleaseLink',
        mainData: this.editItem,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchCampaigns()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeCampaignRelease()

        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancelCampaignReleaseRequest (idPtKampagne) {
      axios.get('/api/kampagne/?aktion=cancelCampaignReleaseRequest&mainData[idPtKampagne]='+idPtKampagne)
        .then(
          (response) =>{
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            this.fetchCampaigns();
          }
        )
        .catch((error) => {
          console.log(error)
        })
    },
    stepBackCampaignRelease () {
      this.$nextTick(() => {
        if(this.campaignReleaseConfig.showMailer){
          this.campaignReleaseConfig.showMailer = false;
          this.campaignReleaseConfig.showStep1 = true;
        }
        else if(this.campaignReleaseConfig.showLink){
          this.campaignReleaseConfig.showLink = false;
          this.campaignReleaseConfig.showStep1 = true;
        }
      })
    },
    closeCampaignRelease () {
      this.$nextTick(() => {
        this.dialogCampaignRelease = false
        this.editIndex = -1
        this.editItem = this.defaultItem
        this.campaignReleaseConfig = JSON.parse(JSON.stringify(this.campaignReleaseDefaultConfig))
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },
    copyText() {
      this.$clipboard(this.editItem.freigabeLink);
      this.vm.$toastr.s('Link Kopiert');
    }
  }
}
</script>

<style lang="scss" >
.v-card__title {
  word-break: normal;
}
.v-alert {
  width: 100%;
}
.v-tab{
  max-width: unset;
}

.whitespace-wrap{
  white-space: wrap;
}

/*table th:last-child,
table td:last-child{
  position: sticky !important;
  right: 0 !important;
  background-color: white !important;
  z-index: 1 !important; !* Damit die letzte Spalte über anderen Zellen bleibt *!
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1) !important;
}*/
</style>
